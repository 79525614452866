
function About() {
    return(
        <div class="pt-20 pb-16 text-4xl text-center">
            <h1>Coming Soon!</h1>
        </div>

    );
};

export default About;